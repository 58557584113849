// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// selectedGreen colors

$sgLightGreen100: #E6FFF3;
$sgLightGreen150: #D9FFED;
$sgLightGreen300: #B4FFDB;
$sgLightGreen900: #04FF87;

$sgDarkGreen50: #EBF9F4;
$sgDarkGreen150: #CBF6E4;
$sgDarkGreen300: #B4E9D4;
$sgDarkGreen700: #38C58D;
$sgDarkGreen900: #06B770;
$sgDarkGreen950: #05A867;

$sgPureWhite: #ffffff;
$sgBackground900: #F7FFFB;

$sgBlack600: #646464;
$sgBlack700: #353535;
$sgBlack900: #111111;

$sgGreen: #06b770;
$lightSgGreen: #04ff87;

$sgLightBlue: #39B0D3;

$sgPurple: #753A57;

// selectedGreen colors

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $sgBlack900,
  "white": $sgPureWhite,
  "gray": $sgBlack600,
  "gray-dark": $sgBlack700,
  "sgGreen": $sgDarkGreen900,
  "lightSgGreen": $sgLightGreen900
  ) !default;
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $sgBlack700 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "black": $sgBlack900,
  "dark": $dark,
  "sgGreen": $sgDarkGreen900,
  "lightSgGreen": $sgLightGreen900
) !default;
// scss-docs-end theme-colors-map


// h1 style
@mixin sgih1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 66px;
}

// h2 style
@mixin sgih2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 64px;
}
.h240bold {
  @include sgih2
}
// h3 style
@mixin sgih3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
}
.h326bold {
  @include sgih3
}

// title 1 style
@mixin sgit1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
}

// title 2 style
@mixin sgit2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
}

// body 1 styles
@mixin sgib1b {
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
}

@mixin sgib1sb {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
}

@mixin sgib1l {
  font-weight: 300;
  font-size: 22px;
  line-height: 34px;
}

.body22bold {
  @include sgib1b
}

.body22semibold {
  @include sgib1sb
}

.body22light {
  @include sgib1l
}

.body22GreenBold {
  @include sgib1b;
  color: $sgDarkGreen700;
  letter-spacing: 1px;
}

// body 2 styles
@mixin sgib2b {
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
}

@mixin sgib2sb {
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
}

@mixin sgib2r {
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
}

@mixin sgib2l {
  font-weight: 300;
  font-size: 18px;
  line-height: 34px;
}

.body18bold {
  @include sgib2b
}

.body18semibold {
  @include sgib2sb
}

.body18regular {
  @include sgib2r
}

.body18light {
  @include sgib2l
}

// body 3 styles
@mixin sgib3b {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
}

@mixin sgib3sb {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

@mixin sgib3r {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}

@mixin sgib3l {
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
}

.body16bold {
  @include sgib3b
}

.body16semibold {
  @include sgib3sb
}

.body16regular {
  @include sgib3r
}

.body16light {
  @include sgib3l
}

// body 4 styles
@mixin sgib4b {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

@mixin sgib4sb {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

@mixin sgib4r {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.body12bold {
  @include sgib4b
}

.body12semibold {
  @include sgib4sb
}
.body12regular {
  @include sgib4sb
}

// body 5 styles
@mixin sgib5b {
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
}

@mixin sgib5sb {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

@mixin sgib5r {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}

@mixin sgib5r2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}

// body 6 styles
@mixin sgib6b {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

@mixin sgib6sb {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

@mixin sgib6r {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
}

@mixin sgib6r2 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

//// margins
.mb-60{
  margin-bottom: 60px;
}
.mt-12{
  margin-top: 12px;
}


//// rectangles
.frame {
  width: 170px;
  height: 170px;
  background-size: 170px 170px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &.rectangle-tl {
    background-image: url('/assets/img/Rectangle_tl.svg');
  }
  &.rectangle-tr {
    background-image: url('/assets/img/Rectangle_tr.svg');
  }
  &.rectangle-bl {
    background-image: url('/assets/img/Rectangle_bl.svg');
  }
  &.rectangle-br {
    background-image: url('/assets/img/Rectangle_br.svg');
  }
  img {
    width: 84px;
    height: 84px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -42px;
    margin-left: -42px;
  }
}

//// buttons
.btn-lightSgGreen {
  &:hover {
    animation: lightSgGreenBack-change .3s normal forwards linear;
  }
}
@keyframes lightSgGreenBack-change {
  from {
    background: $lightSgGreen;
    border-color: $lightSgGreen;
    color: $sgBlack900;
  }

  to {
    background: darken($lightSgGreen, 20);
    border-color: darken($lightSgGreen, 20);
    color: $sgPureWhite;
  }
}

.btn-sgGreen {
  &:hover {
    animation: sgGreenBack-change .3s normal forwards linear;
  }
}

@keyframes sgGreenBack-change {
  from {
    border-color: $sgGreen;
    background: $sgGreen;
    color: $sgPureWhite;
  }

  to {
    border-color: $sgLightGreen900;
    background: $sgLightGreen900;
    color: $sgPureWhite;
  }
}