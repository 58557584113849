/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins');

@import "assets/scss/theme";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

body {
    font-family: 'Poppins';
}